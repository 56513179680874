import os from "os";

type LoggingColors = "blue" | "green" | "red" | "yellow";

export const embolden = (s: string) => `\u001b[1m${s}\u001b[22m`;

export const formatLogMessage = (s: string, color: LoggingColors = "blue") => {
  let loggedString = s;
  switch (color) {
    case "blue":
      loggedString = `\x1b[94m${s}\x1b[0m`;
      break;
    case "green":
      loggedString = `\u001b[32m${s}\x1b[0m`;
      break;
    case "red":
      loggedString = `\u001b[31m${s}\x1b[0m`;
      break;
    case "yellow":
      loggedString = `\u001b[33m${s}\x1b[0m`;
      break;
  }
  return loggedString;
};

export const logError = (error: unknown, ...messages: string[]) => {
  // eslint-disable-next-line no-console, @typescript-eslint/restrict-template-expressions
  console.error(`\n\x1b[91m[ERROR]: ${error}\x1b[0m\n`, ...messages);
};

export const logWarning = (message: unknown, ...messages: string[]) => {
  // eslint-disable-next-line no-console, @typescript-eslint/restrict-template-expressions
  console.warn(`\n\x1b[93m[WARNING]: ${message}\x1b[0m\n`, ...messages);
};

export const logInfo = (message: unknown, ...messages: string[]) => {
  // eslint-disable-next-line no-console, @typescript-eslint/restrict-template-expressions
  console.info(formatLogMessage(`[INFO]: ${message}`), ...messages);
};

export const log = (toLog: unknown = "") => {
  // eslint-disable-next-line no-console, @typescript-eslint/restrict-template-expressions
  console.log(toLog);
};

export const logMemoryUsage = (area = "") => {
  if (!process.env.MEMORY_USAGE_LOGGING) return;
  const convertToGB = (bytes: number) =>
    `${Math.round((bytes / 1024 / 1024 / 1024) * 100) / 100} GB`;
  log(formatLogMessage(`${area} memory usage:`, "yellow"));
  const memoryUsed = process.memoryUsage();
  if (os) {
    log(formatLogMessage(`[MEMORY]: Total system memory: ${convertToGB(os.totalmem())}`, "yellow"));
    log(formatLogMessage(`[MEMORY]: Free memory: ${convertToGB(os.freemem())}`, "yellow"));
    log(formatLogMessage(`[MEMORY]: CPU count: ${os.cpus()?.length ?? ""}`, "yellow"));
  }
  if (memoryUsed) {
    Object.entries(memoryUsed).forEach(([key, value]) => {
      log(formatLogMessage(`[MEMORY]: ${key}: ${convertToGB(value)}`, "yellow"));
    });
  }
  log();
};
