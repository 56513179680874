import { SimplePaletteColorOptions } from "@mui/material";
import fromPairs from "lodash/fromPairs";

import { SliderKeys } from "common/components/Ranges/types/types";

import { AGE_GROUPS } from "common/constants";

export type DemographicKeys = "race" | "ethnicity" | "age" | "ageGroups" | "sex" | "grade";
export type GeographyKeys = "benchmark" | "selected" | "comparison";
type BrandKeys = "brand" | "brandSecondary" | "success";
export type CountyKeys = "kent" | "sussex" | "new-castle";
type MUIColorPalette = Record<keyof SimplePaletteColorOptions, Required<string>>;
export type MUIColors = Record<BrandKeys, MUIColorPalette>;
export type Colors = Record<string, Required<Color>>;
export type Color = Record<string, Required<string>>;
type AdditionalColors = Record<string, string>;

export type DataColors = {
  geography: Record<GeographyKeys, Required<string>>;
  county: Record<CountyKeys, Required<string>>;
};

export const brandPalette: MUIColors = {
  brand: {
    light: "#F0F3F8",
    main: "#245194",
    dark: "#1F334F",
    contrastText: "#FFFFFF"
  },
  brandSecondary: {
    light: "#FFF9F8",
    main: "#942C1B",
    dark: "#3E120B",
    contrastText: "#FFFFFF"
  },
  success: {
    light: "#F1FBF2",
    main: "#307D33",
    dark: "#133315",
    contrastText: "#FFFFFF"
  }
};

export const linkColor = "#0060F0";

export const additionalPalette: Record<"component", AdditionalColors> = {
  component: {
    background: "#FAFAFA",
    links: linkColor,
    textColor: "rgba(0, 0, 0, 0.87)",
    hoverBackground: "rgba(36, 81, 148, 0.12)",
    lightBlackBorder: "rgba(0, 0, 0, 0.23)",
    lightBackground: "#eee"
  }
};

// Geography/Location colors
export const geographyColors: DataColors["geography"] = {
  benchmark: "#BDBDBD",
  selected: "#1E90FF",
  comparison: "#FFA51E"
};

export const countyColors: DataColors["county"] = {
  ["new-castle"]: "#C299DB",
  ["kent"]: "#7B9F27",
  ["sussex"]: "#41C3A8"
};
export const yellowGreenBlueViolet = [
  "#FDE725",
  "#C8E020",
  "#90D743",
  "#5EC962",
  "#35B779",
  "#20A486",
  "#21918C",
  "#287C8E",
  "#31688E",
  "#3B528B",
  "#443983",
  "#481F70",
  "#440154"
];

// After executing reverse yellowGreenBlueViolet is reversed too.
export const violetBlueGreenYellow = [...yellowGreenBlueViolet].reverse();

export const demographicColors: Record<DemographicKeys, Required<Color>> = {
  race: {
    white: "#336770",
    black: "#E6B70F",
    asian: "#1F77B4",
    multiracial: "#8BC695",
    otherRace: "#B4AFFF",
    nativeAmerican: "#A0DEE7",
    pacificIslander: "#F7C390"
  },
  ethnicity: {
    nonHispanic: "#CF9C5B",
    hispanic: "#793F88"
  },
  sex: {
    male: "#0D3C61",
    female: "#8B84FF"
  },
  grade: {
    eight: "#BE3723",
    eleven: "#671E13"
  },
  age: fromPairs(yellowGreenBlueViolet.map((c, i) => [i + 1, c])),
  ageGroups: fromPairs(
    AGE_GROUPS.map((g, i) => [g, violetBlueGreenYellow[i > 0 ? i + i : i] as string])
  )
};

export const colorDescriptions: Record<string, string> = {
  brand: "Blue",
  brandSecondary: "Maroon",
  success: "Green",
  race: "Multiracial to be used in all instances of “Multi racial, Two + Races, etc.",
  ethnicity: "Non-Hispanic is the correct way to label this ethnicity",
  age: "Age groups are a special case that is representing using the Viridis color palette."
};

export const sliderSectionColors: Record<SliderKeys, string[]> = {
  svi: ["#F0EEF5", "#C4C2DC", "#928FBD", "#5B4894"],
  decreasingIncreasing: ["#459C99", "#5BC89C", "#FFFEA6", "#F4BA7C", "#ED9264"],
  default: ["#459C99", "#FFFEA6", "#ED9264"]
};
