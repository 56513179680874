"use client";

import { ReactNode } from "react";
import Image from "next/image";
import { Box, Card, Container, Grid, Link, Typography } from "@mui/material";
import deImage from "public/images/404/de.svg";

import { ECDC_NAME } from "common/constants";
import { DEFAULT_FLU_SEASON } from "app/topics/flu/[subTopicId]/[locationId]/[seasonId]/util/season";

import { FuzzySearch } from "layout/SiteTopNav/FuzzySearch/FuzzySearch";
import { StyledList } from "common/components/lists/StyledList";
import { UnstyledList } from "common/components/lists/UnstyledList";

const DEFAULT_404_PADDING = { xs: 4, lg: 10 };
const DEFAULT_404_MARGIN = { xs: 4, lg: 6 };
const CARD_BORDER_STYLE = {
  borderColor: "borders.light",
  borderWidth: 1,
  borderStyle: "solid"
};

interface InsetCardProps {
  id?: string;
  title: string;
  image?: {
    src: string;
    alt: string;
  };
  content?: ReactNode;
  link?: [string, string] | null;
  children?: ReactNode;
}

const InsetCard: React.FC<InsetCardProps> = ({ title, image, content, link, children }) => (
  <Card
    sx={{
      p: { xs: 2, lg: 4 },
      display: "flex",
      flexDirection: "row",
      gap: 4,
      flexGrow: 1,
      height: "100%",
      ...CARD_BORDER_STYLE
    }}
    elevation={0}
  >
    {image && (
      <Box style={{ flex: "0 0 75px", height: "100%", position: "relative" }}>
        <Image src={image.src} alt={image.alt} layout="fill" objectFit="contain" />
      </Box>
    )}
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2
      }}
    >
      <Typography variant="h6" sx={{ color: "brand.main" }}>
        {title}
      </Typography>
      {content || children}
      {link && <Link href={link[1]}>{link[0]}</Link>}
    </Box>
  </Card>
);

export default function Custom404() {
  const popularTopics = [
    { title: "COVID Data Tracker", href: "/locations/state/covid-19" },
    { title: ECDC_NAME, href: "/portals/ecdc/locations/state" },
    {
      title: "Mental Health & Substance Use",
      href: "/topics/mental-health-substance-use/locations/state"
    },
    {
      title: "Influenza Dashboard",
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      href: `/portals/flu/locations/state/seasons/${DEFAULT_FLU_SEASON}/overview`
    }
  ];
  const cards: InsetCardProps[] = [
    {
      id: "topics",
      title: "Popular Topics",
      image: undefined,
      content: (
        <UnstyledList>
          {popularTopics.map(({ title, href }) => (
            <Box component="li" key={title}>
              <Link href={href}>{title}</Link>
            </Box>
          ))}
        </UnstyledList>
      ),
      link: null
    },
    {
      id: "data-downloads",
      title: "Data Downloads",
      image: undefined,
      content: (
        <Typography>
          The data dictionary enumerates all the datasets represented in charts and graphs on the My
          Health Community site. Go here to download the data.{" "}
        </Typography>
      ),
      link: ["View data dictionary", "/data-dictionary"]
    },
    {
      id: "search",
      title: "Search Locations",
      image: {
        src: deImage,
        alt: "Simple map of Delaware"
      },
      content: (
        <Typography>
          Lookup Delaware zip codes, census tracts, counties, cities and more.
        </Typography>
      ),
      link: ["View all locations", "/search"]
    }
  ];
  return (
    <Box mt={4}>
      <Container maxWidth="xl" sx={{ mb: DEFAULT_404_MARGIN }}>
        <Card
          elevation={0}
          sx={{
            ...CARD_BORDER_STYLE,
            borderColor: { xs: "transparent", md: "rgba(0, 0, 0, 0.23)" },
            mx: {
              xs: -2,
              md: 4
            }
          }}
        >
          <Box
            component="section"
            sx={{
              backgroundColor: "brand.light",
              p: DEFAULT_404_PADDING,
              display: "flex",
              flexDirection: "column",
              gap: DEFAULT_404_MARGIN
            }}
          >
            <Box display="flex" flexDirection="column" gap={1}>
              <Typography component="h1" variant="h1">
                Page Not Found
              </Typography>
              <Typography>
                Sorry, we can’t seem to find the page you are looking for. Please try searching for
                topics and locations below.
              </Typography>
            </Box>
            <Box sx={{ minHeight: 60, position: "relative" }}>
              <FuzzySearch
                uiLocation="404 Page"
                minHeight={60}
                modalProps={{ width: "100%" }}
                showRecentSearchesUnderInput
              />
            </Box>
          </Box>
          <Box p={DEFAULT_404_PADDING}>
            <Grid container display="flex" spacing={DEFAULT_404_MARGIN} mb={DEFAULT_404_MARGIN}>
              {cards.map((card) => (
                <Grid item xs={12} md={6} lg={4} sx={{ flexGrow: 1 }} key={card.id}>
                  <InsetCard {...card} />
                </Grid>
              ))}
            </Grid>

            <InsetCard title="Next Steps">
              <StyledList>
                <Box component="li">
                  Verify that the URL you entered is correct or return to the{" "}
                  <Link href="/home">homepage</Link>.
                </Box>
                <Box component="li">
                  If you are still unable to find what you are looking for or would like to report a
                  broken link, please <Link href="/feedback/new">contact us</Link>.
                </Box>
              </StyledList>
            </InsetCard>
          </Box>
        </Card>
      </Container>
    </Box>
  );
}
