export const SITE_NAME = "My Healthy Community";

export const ECDC_NAME = "Equity Counts Data Center";

export const ASTHMA_NAME = "Asthma";

export const COVID_NAME = "COVID-19";
export const COVID_DASHBOARD_NAME = `${COVID_NAME} Dashboard`;

export const STATE_LOCATION_NAME = "Delaware";

export const FLU_NAME = "Influenza Dashboard";

export const COUNTIES = {
  newCastle: {
    name: "New Castle County",
    id: "county-new-castle"
  },
  kent: {
    name: "Kent County",
    id: "county-kent"
  },
  sussex: {
    name: "Sussex County",
    id: "county-sussex"
  }
};
export const AGE_GROUPS = ["65+", "50-64", "35-49", "18-34", "12-17", "5-11", "0-4"];

export const LAST_CENSUS_YEAR = 2021;

export const SUPPRESSION_MESSAGE = `Please note: Counts of less than 11 are suppressed. If the population is sufficiently large, 0 counts may be shown. Rates based on counts less than 20 may be unstable.`;
